import { useState, ReactNode, FC } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme, Theme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

import {
	AccordionsAccordionsItem,
	ContentTypeRichText as TContentTypeRichText,
	Maybe,
	AccordionsButtonsCollection,
} from '@/types/generated';
import { usePageThemeContext } from '@/context/PageThemeProvider.ctx';
import { textToJumpLink } from '@/utils';
import ContentfulButton from '@/components/ContentfulButton';
import {
	ContentAlignment,
	HeadingTag,
	TAGContentAlignment,
	TAccordionExpandIconType,
	TAccordionExpandIconPosition,
	TRoundedCornersSize,
} from '@/types';
import resolveContentfulHeadingTag from '@/utils/resolveContentfulHeadingTag';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';

import ContentTypeRichText from '../../ContentTypeRichText';

import {
	wrapper,
	getAccordionStyles,
	getPageThemedAccordionStyles,
	getAccordionSummaryStyles,
	getPageThemedAccordionSummaryStyles,
	getAccordionDetailsStyles,
	summaryIconStyles,
} from './AccordionContainer.styles';

function isContentfulRichText(item: ExtendedAccordionsAccordionsItem): item is TContentTypeRichText {
	return '__typename' in item && item.__typename === 'ContentTypeRichText';
}

type ExtendedAccordionsAccordionsItem =
	| AccordionsAccordionsItem
	| {
			id: string;
			title: string;
			children: ReactNode;
	  };

interface IAccordionContainer {
	items: Array<Maybe<ExtendedAccordionsAccordionsItem>>;
	isSmallScreen: boolean;
	buttonsCollection?: Maybe<AccordionsButtonsCollection>;
	buttonCollectionAlignment?: Maybe<string>;
	isDark?: boolean;
	accordionsHeadingTag?: Maybe<HeadingTag>;
	expandIconType?: Maybe<TAccordionExpandIconType>;
	expandIconPosition?: Maybe<TAccordionExpandIconPosition>;
	withInnerGap?: Maybe<boolean>;
	roundedCornersSize?: Maybe<TRoundedCornersSize>;
	titleHtag?: Maybe<string>;
}

const TAGAccordion: FC<{
	id: string;
	title?: Maybe<string>;
	theme: Theme;
	isSmallScreen: boolean;
	pageThemeBgColor: string;
	expandedAccordionId: string | null;
	children: ReactNode;
	expandIcon: ReactNode;
	handleAccordionChange: (accId: string) => void;
	headingTag: HeadingTag;
	expandIconPosition?: TAccordionExpandIconPosition;
	withInnerGap?: boolean;
	roundedCornersSize?: Maybe<TRoundedCornersSize>;
	summaryIcon?: IconName;
}> = ({
	id,
	title,
	theme,
	isSmallScreen,
	pageThemeBgColor,
	expandedAccordionId,
	children,
	expandIcon,
	handleAccordionChange,
	headingTag,
	expandIconPosition = 'Left',
	roundedCornersSize = 'Small',
	withInnerGap = false,
	summaryIcon,
}) => {
	return (
		<Accordion
			elevation={0}
			key={id}
			id={textToJumpLink(title)}
			sx={
				theme.tagFeatures?.PAGE_THEME_ENABLED
					? getPageThemedAccordionStyles({ pageThemeBgColor })
					: getAccordionStyles({ roundedCornersSize, withInnerGap, isSmallScreen })
			}
			expanded={expandedAccordionId === id}
			onChange={() => handleAccordionChange(id)}
			data-test-id={`section_accordion_item_${id}`}
		>
			<AccordionSummary
				sx={
					!theme.tagFeatures?.PAGE_THEME_ENABLED
						? getAccordionSummaryStyles({ isSmallScreen, expandIconPosition })
						: getPageThemedAccordionSummaryStyles({ isSmallScreen, expandIconPosition })
				}
				aria-controls={`${title ?? ''}-content`}
				expandIcon={expandIcon}
			>
				{summaryIcon && (
					<TAGSvgIcon
						data-test-id={`section_accordion_item_icon_${id}`}
						icon={summaryIcon}
						htmlColor="tertiary.main"
						style={summaryIconStyles}
						size={24}
					/>
				)}
				<Typography
					component={resolveContentfulHeadingTag(headingTag)}
					variant="header4"
					data-test-id={`text_accordion_summary_${id}`}
				>
					{title ?? 'Untitled'}
				</Typography>
			</AccordionSummary>
			<AccordionDetails sx={getAccordionDetailsStyles({ hasSummaryIcon: Boolean(summaryIcon) })}>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default function AccordionContainer({
	items,
	isSmallScreen,
	buttonsCollection,
	buttonCollectionAlignment = 'Center',
	isDark = false,
	expandIconType = 'Expand',
	expandIconPosition = 'Left',
	withInnerGap = false,
	roundedCornersSize = 'Small',
	titleHtag,
}: Readonly<IAccordionContainer>) {
	const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);

	function handleAccordionChange(accId: string) {
		setExpandedAccordion((prevState) => (prevState === accId ? null : accId));
	}
	const theme = useTheme();
	const getExpandableIcon = (
		id: string,
		expandedAccordion: string | null,
		expandIconType: Maybe<TAccordionExpandIconType>
	) => {
		if (theme.tagFeatures?.PAGE_THEME_ENABLED) {
			return expandedAccordion === id ? (
				<RemoveIcon fontSize="medium" color="secondary" />
			) : (
				<AddIcon fontSize="medium" color="secondary" />
			);
		}
		if (expandIconType === 'Add/remove circle outline') {
			return expandedAccordion === id ? (
				<RemoveCircleOutlineRoundedIcon fontSize="large" />
			) : (
				<AddCircleOutlineRoundedIcon fontSize="large" />
			);
		}
		return <ExpandMoreIcon fontSize="large" />;
	};
	const pageTheme = usePageThemeContext();
	const pageThemeBgColor =
		pageTheme === 'Primary' ? 'backgrounds.light' : `backgrounds.${pageTheme.toLowerCase().replace(' ', '')}`;

	return (
		<>
			<Box data-test-id="section_accordion_container" sx={wrapper}>
				{items.map((item) => {
					if (!item) return null;
					const { title: _, ...rest } = item;

					if (isContentfulRichText(item)) {
						const itemHeadingTag =
							item.titleHeading === 'inherit'
								? (titleHtag as HeadingTag)
								: (item.titleHeading as HeadingTag);

						return (
							<TAGAccordion
								id={item.sys.id}
								key={item.sys.id}
								title={item.title}
								theme={theme}
								isSmallScreen={isSmallScreen}
								pageThemeBgColor={pageThemeBgColor}
								expandedAccordionId={expandedAccordion}
								expandIcon={getExpandableIcon(item.sys.id, expandedAccordion, expandIconType)}
								handleAccordionChange={handleAccordionChange}
								headingTag={itemHeadingTag}
								expandIconPosition={expandIconPosition ?? 'Left'}
								withInnerGap={withInnerGap ?? false}
								roundedCornersSize={roundedCornersSize ?? 'Small'}
								summaryIcon={item.titleIcon as IconName}
							>
								<ContentTypeRichText
									{...rest}
									dataTestId={`group_accordion_details_${item.sys.id}`}
									backgroundColor="transparent"
									disablePaddingOn={['Top', 'Bottom']}
									disableGutters
									containerSx={{
										maxWidth: { md: 'none' },
									}}
								/>
							</TAGAccordion>
						);
					}

					if ('children' in item) {
						return (
							<TAGAccordion
								id={item.id}
								key={item.id}
								title={item.title}
								theme={theme}
								isSmallScreen={isSmallScreen}
								pageThemeBgColor={pageThemeBgColor}
								expandedAccordionId={expandedAccordion}
								expandIcon={getExpandableIcon(item.id, expandedAccordion, expandIconType)}
								handleAccordionChange={handleAccordionChange}
								headingTag="h4"
								expandIconPosition={expandIconPosition ?? 'Left'}
								withInnerGap={withInnerGap ?? false}
								roundedCornersSize={roundedCornersSize ?? 'Small'}
							>
								{item.children}
							</TAGAccordion>
						);
					}

					return null;
				})}
			</Box>
			<Box display="flex" justifyContent={ContentAlignment[buttonCollectionAlignment as TAGContentAlignment]}>
				{buttonsCollection?.items.map((button) =>
					button ? (
						<ContentfulButton
							key={button.sys.id}
							{...button}
							defaultVariant={isDark ? 'tertiaryHC' : 'tertiaryDefault'}
							dataTestId="button_collection"
						/>
					) : null
				)}
			</Box>
		</>
	);
}
